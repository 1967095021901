export class Invoice {
  erpId: number;
  erpCode: string;
  date: string;
  centerErpId: number;
  lines: InvoiceLine[];

  constructor(invoice?: Partial<Invoice>) {
    this.erpId = invoice?.erpId ?? 0;
    this.erpCode = invoice?.erpCode ?? '';
    this.date = invoice?.date ?? '';
    this.centerErpId = invoice?.centerErpId ?? 0;
    this.lines = invoice?.lines ?? [];
  }
}

export class InvoiceLine {
  label: string;
  state: string;
  tickets: {
    id: number,
    typeDetail: 'SOUSCRIPTION' | 'PRODUIT_CADEAU' | 'RENDEZ_VOUS',
    typeProduit: 'CHEQUE_CADEAU' | 'BON_CADEAU'
    typeSouscription: 'COMPTE_SEANCE' | 'COMPTE_CREDIT_TEMPS'
  }[];
  productErpId: number;
  quantity: number;

  constructor(invoiceLine?: Partial<InvoiceLine>) {
    this.label = invoiceLine?.label ?? '';
    this.state = invoiceLine?.state ?? '';
    this.productErpId = invoiceLine?.productErpId ?? 0;
    this.quantity = invoiceLine?.quantity ?? 0;
    this.tickets = invoiceLine?.tickets ?? [];
  }
}
