export class RendezVous {
  erpId: number;
  centerErpId: number;
  seanceErpId: number;
  prestationErpId: number;
  label: string;
  date: string;
  start: string;
  end: string;
  waiting: boolean;
  calendar: { google: string, ical: string, outlook: string };

  constructor(credit?: Partial<RendezVous>) {
    this.erpId = credit?.erpId ?? 0;
    this.centerErpId = credit?.centerErpId ?? 0;
    this.seanceErpId = credit?.seanceErpId ?? 0;
    this.prestationErpId = credit?.prestationErpId ?? 0;
    this.label = credit?.label ?? '';
    this.date = credit?.date ?? '';
    this.start = credit?.start ?? '';
    this.end = credit?.end ?? '';
    this.waiting = credit?.waiting ?? false;
    this.calendar = credit?.calendar ?? { google: '', ical: '', outlook: '' };
  }
}
