export class Seance {
  date: string;
  start: string;
  end: string;
  duration: number;
  erpId: number;
  course: { erpId: number, label: string, icon: string, color: string };
  capacity: number;
  remainingCapacity: number;
  waitingCapacity: number;
  remainingWaitingCapacity: number;
  participating: 'no' | 'yes' | 'waiting';

  constructor(credit?: Partial<Seance>) {
    this.erpId = credit?.erpId ?? 0;
    this.course = credit?.course ?? { erpId: 0, label: '', icon: 'vitalite.svg', color: '#EFF6FF' };
    this.date = credit?.date ?? '';
    this.start = credit?.start ?? '';
    this.end = credit?.end ?? '';
    this.duration = credit?.duration ?? 0;
    this.capacity = credit?.capacity ?? 0;
    this.remainingCapacity = credit?.remainingCapacity ?? 0;
    this.waitingCapacity = credit?.waitingCapacity ?? 0;
    this.remainingWaitingCapacity = credit?.remainingWaitingCapacity ?? 0;
    this.participating = credit?.participating ?? 'no';
  }
}
